<template>
  <b-form-group
    label="Lokasyon"
    label-for="location"
  >
    <v-select
      id="location"
      v-model="dataFilter.location"
      placeholder="Lokasyon"
      :options="filterParams.locations"
      :reduce="item => item.value"
      label="label"
    />
  </b-form-group>
</template>
<script>
import vSelect from 'vue-select'
import { BFormGroup } from 'bootstrap-vue'

export default {
  name: 'SelectLocation',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    dataFilter() {
      return this.$store.getters['serviceRPA/dataFilter']
    },
    filterParams() {
      return this.$store.getters['serviceRPA/filterParams']
    },
  },
}
</script>
