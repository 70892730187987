<template>
  <b-form-group
    label="Danışman"
    label-for="user"
  >
    <v-select
      id="user"
      v-model="dataFilter.user"
      placeholder="Danışman"
      :options="filterParams.users"
      :reduce="item => item.value"
      label="label"
    />
  </b-form-group>
</template>
<script>
import vSelect from 'vue-select'
import { BFormGroup } from 'bootstrap-vue'

export default {
  name: 'SelectUser',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    dataFilter() {
      return this.$store.getters['serviceRPA/dataFilter']
    },
    filterParams() {
      return this.$store.getters['serviceRPA/filterParams']
    },
  },
}
</script>
